const autoNgTemplateLoaderTemplate1 = require('./kube-services.html');

import angular from 'angular';
import controller from './kube-services.controller';

angular.module('portainer.kubernetes').component('kubeServicesView', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller,
  bindings: {
    formValues: '=',
    isEdit: '<',
    namespaces: '<',
    loadbalancerEnabled: '<',
  },
});
