const autoNgTemplateLoaderTemplate1 = require('./ingress-table.html');

import angular from 'angular';
import controller from './ingress-table.controller';

angular.module('portainer.kubernetes').component('kubernetesApplicationIngressTable', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller,
  bindings: {
    application: '<',
    publicUrl: '<',
  },
});
