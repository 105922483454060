const autoNgTemplateLoaderTemplate1 = require('./edgeStackEndpointsDatatable.html');

import angular from 'angular';

import { EdgeStackEndpointsDatatableController } from './edgeStackEndpointsDatatableController';

angular.module('portainer.edge').component('edgeStackEndpointsDatatable', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: EdgeStackEndpointsDatatableController,
  bindings: {
    titleText: '@',
    titleIcon: '@',
    tableKey: '@',
    orderBy: '@',
    reverseOrder: '<',
    retrievePage: '<',
    edgeStackId: '<',
    endpointsStatus: '<',
  },
});
