const autoNgTemplateLoaderTemplate1 = require('./edge-stack-deployment-type-selector.html');

import angular from 'angular';
import controller from './edge-stack-deployment-type-selector.controller.js';

export const edgeStackDeploymentTypeSelector = {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller,

  bindings: {
    value: '<',
    onChange: '<',
    hasDockerEndpoint: '<',
  },
};

angular.module('portainer.edge').component('edgeStackDeploymentTypeSelector', edgeStackDeploymentTypeSelector);
