const autoNgTemplateLoaderTemplate1 = require('./applications-datatable-url.html');

import angular from 'angular';
import './applications-datatable-url.css';

angular.module('portainer.kubernetes').component('kubernetesApplicationsDatatableUrl', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  bindings: {
    publishedUrl: '@',
  },
});
