const autoNgTemplateLoaderTemplate1 = require('./dashboard.html');

angular.module('portainer.kubernetes').component('kubernetesDashboardView', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: 'KubernetesDashboardController',
  controllerAs: 'ctrl',
  bindings: {
    endpoint: '<',
  },
});
