const autoNgTemplateLoaderTemplate1 = require('./associatedEndpointsSelector.html');

import angular from 'angular';
import AssociatedEndpointsSelectorController from './associatedEndpointsSelectorController';

angular.module('portainer.app').component('associatedEndpointsSelector', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: AssociatedEndpointsSelectorController,
  bindings: {
    endpointIds: '<',
    tags: '<',
    groups: '<',
    hasBackendPagination: '<',

    onAssociate: '<',
    onDissociate: '<',
  },
});
