const autoNgTemplateLoaderTemplate1 = require('./datatable-titlebar.html');

export const datatableTitlebar = {
  bindings: {
    icon: '@',
    title: '@',
    feature: '@',
    featherIcon: '<',
  },
  templateUrl: autoNgTemplateLoaderTemplate1,
};
