const autoNgTemplateLoaderTemplate1 = require('./git-form-additional-files-panel.html');

import controller from './git-form-additional-files-panel.controller.js';

export const gitFormAdditionalFilesPanel = {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller,
  bindings: {
    model: '<',
    onChange: '<',
  },
};
