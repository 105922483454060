const autoNgTemplateLoaderTemplate1 = require('./environment-variables-simple-mode-item.html');

import angular from 'angular';
import controller from './environment-variables-simple-mode-item.controller.js';

export const environmentVariablesSimpleModeItem = {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller,

  bindings: {
    variable: '<',
    index: '<',

    onChange: '<',
    onRemove: '<',
  },
};

angular.module('portainer.app').component('environmentVariablesSimpleModeItem', environmentVariablesSimpleModeItem);
