const autoNgTemplateLoaderTemplate1 = require('./fileUploader.html');

import angular from 'angular';
import { FileUploaderController } from './fileUploaderController';

angular.module('portainer.agent').component('fileUploader', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: FileUploaderController,
  bindings: {
    uploadFile: '<onFileSelected',
  },
});
