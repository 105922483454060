const autoNgTemplateLoaderTemplate1 = require('./datatable-sort-icon.html');

import controller from './datatable-sort-icon.controller';

export const datatableSortIcon = {
  bindings: {
    key: '@',
    selectedSortKey: '@',
    reverseOrder: '<',
  },
  controller,
  templateUrl: autoNgTemplateLoaderTemplate1,
};
