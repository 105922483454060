const autoNgTemplateLoaderTemplate1 = require('./services-table.html');

import angular from 'angular';

angular.module('portainer.kubernetes').component('kubernetesApplicationServicesTable', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  bindings: {
    services: '<',
    application: '<',
    publicUrl: '<',
  },
});
