const autoNgTemplateLoaderTemplate1 = require('./git-form-additional-file-item.html');

import controller from './git-form-additional-file-item.controller.js';

export const gitFormAdditionalFileItem = {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller,

  bindings: {
    variable: '<',
    index: '<',

    onChange: '<',
    onRemove: '<',
  },
};
