const autoNgTemplateLoaderTemplate1 = require('./configuration.html');

angular.module('portainer.kubernetes').component('kubernetesConfigurationView', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: 'KubernetesConfigurationController',
  controllerAs: 'ctrl',
  bindings: {
    $transition$: '<',
  },
});
