const autoNgTemplateLoaderTemplate1 = require('./edgeStacksView.html');

import angular from 'angular';

import { EdgeStacksViewController } from './edgeStacksViewController';

angular.module('portainer.edge').component('edgeStacksView', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: EdgeStacksViewController,
});
