const autoNgTemplateLoaderTemplate1 = require('./roles-datatable.html');

import controller from './roles-datatable.controller';
import './roles-datatable.css';

export const rolesDatatable = {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller,
  bindings: {
    titleText: '@',
    titleIcon: '@',
    dataset: '<',
    tableKey: '@',
    orderBy: '@',
    reverseOrder: '<',
  },
};
