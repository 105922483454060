const autoNgTemplateLoaderTemplate1 = require('./git-form-auto-update-fieldset.html');

import controller from './git-form-auto-update-fieldset.controller.js';

export const gitFormAutoUpdateFieldset = {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller,
  bindings: {
    model: '<',
    onChange: '<',
    showForcePullImage: '<',
  },
};
