const autoNgTemplateLoaderTemplate1 = require('./codeEditor.html');

angular.module('portainer.app').component('codeEditor', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: 'CodeEditorController',
  bindings: {
    identifier: '@',
    placeholder: '@',
    yml: '<',
    readOnly: '<',
    onChange: '<',
    value: '<',
  },
});
